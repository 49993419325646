@import 'utils';

@media screen and (max-width: 412px) {

    .centered-title {
        font-size: 1.5rem;
    }
    .hero {
        max-width: 100%;
        @include centerFlex;
        h2 {
            font-size: 1.5rem;
        }

        p {
            @include ma;
            max-width: 100%;
            font-size: 0.9rem;
            padding: 1rem;
            word-wrap: break-word;
        }
    }

    .flex-row {
        flex-direction: column;
    }

    .tray {
        display: none;
    }
}
