@mixin wh100 {
    width: 100%;
    height: 100%;
}

@mixin centerFlex {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

@mixin flexRow {
    display: flex;
    flex-direction: row;
}

@mixin flexColumn {
    display: flex;
    flex-direction: column;
}

@mixin grid1 {
    display: grid;
    grid-template-columns: 1fr;
}

@mixin grid2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

@mixin grid3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

@mixin ma {
    margin: auto;
}

@mixin p0 {
    padding: 0;
}

@mixin m0 {
    margin: 0;
}

//colors
$orange: #ffbb38;
$red: #ff5959;
$blue: #8cd6ff;
$dark: #131313;
$dark-active: #4b4b4b;
$light: #DCDCDC;
//transitions
$e: all 0.2s ease;
$ei: all 0.2s ease-in;
$eo: all 0.2s ease-out;
$eio: all 0.2s ease-in-out;
//fonts
$mn: 'Mynerve', sans-serif;
$ns: 'Noto Sans', sans-serif;
$pg: 'Poppins', sans-serif;
$hg: 'Host Grotesk', sans-serif;
$is: 'Inter', sans-serif;
$ds: 'DM Sans', sans-serif;
