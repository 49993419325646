.page {
    @include wh100;
    @include grid1;
    position: absolute;
    top: 3.75rem;
    padding-bottom: 5rem;
}

.page-title-container {
    @include wh100;
    margin-top: 5rem;
}

.page-title {
    @include centerFlex;
    font-size: 4rem;
    font-weight: 700;
    margin-top: 1.5rem;
    margin-bottom: 2rem;
    font-family: $mn;
    transform: perspective(100px) rotateX(5deg);
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.1), 0 4px 0 rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    cursor: pointer;

    &:hover {
        transform: perspective(100px) rotateX(0deg);
    }

    @media (max-width: 1200px) {
        font-size: 3.5rem;
    }

    @media (max-width: 992px) {
        font-size: 3rem;
    }

    @media (max-width: 768px) {
        font-size: 2.5rem;
        margin-top: 1rem;
        margin-bottom: 1.5rem;
    }

    @media (max-width: 576px) {
        font-size: 2rem;
        margin-top: 0.75rem;
        margin-bottom: 1rem;
        transform: none;
        text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);

        &:hover {
            transform: none;
        }
    }
}

.page-content-container {
    @include wh100;
    @include ma;
    padding: 200px 2rem 0; // Add top padding of 60px, keep horizontal padding
    position: absolute; // Position absolutely to move it to the top
    top: 0; // Align to the top of the page
    left: 0; // Align to the left of the page
    right: 0; // Stretch to the right of the page
}

.page-content {
    max-width: 1200px; // Limit the maximum width for better readability
    margin: 0 auto; // Center the content
}

.page-content-list {
    @include centerFlex;
    @include ma;
    flex-direction: column;
    gap: 1rem;
    width: calc(100% - 14rem);
    font-size: 1.2rem;
    font-weight: 400;
    font-family: $ns;
    padding: 2rem;
    text-align: justify;
    line-height: 1.6; // Improve readability
    margin-bottom: 2rem; // Keep space below the title
    background: $light;
    color: $dark;
    
    @media (max-width: 1200px) {
        width: calc(100% - 8rem);
    }
    
    @media (max-width: 992px) {
        width: calc(100% - 4rem);
        font-size: 1.1rem;
    }
    
    @media (max-width: 768px) {
        width: calc(100% - 2rem);
        font-size: 1rem;
        padding: 1.5rem;
    }
    
    @media (max-width: 576px) {
        width: 100%;
        font-size: 0.9rem;
        padding: 1rem;
    }
    
    li {
        list-style: none;
    }
}

.content-title {
    @include centerFlex;
    font-size: 2rem;
    font-weight: 700;
    font-family: $mn;
    margin-top: 2rem;
    margin-bottom: 2rem; // Keep space below the title

    a {
        text-decoration: none;
        color: $light;
        transition: $eio;
        cursor: pointer;
        border-radius: 10px;
        padding: 0.5em;
    
        &:hover {
            transform: scale(1.05);
            color: $orange;
        }
        position: relative;
        &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: -5px;
            width: 100%;
            height: 4px;
            background: url('../components/icons/squiggly_line.svg') repeat-x;
            background-size: auto 100%;
            opacity: 0;
            transition: opacity 0.3s ease, background-position 0.5s linear;
        }

        &:hover::after {
            opacity: 1;
            animation: moveSquiggles 2s linear infinite;
        }
    }
}

.content-body {
    width: calc(100% - 14rem);
    max-width: 100%;
    column-count: 1; // Create two columns
    font-size: 1rem;
    padding: 2rem;
    font-weight: 400;
    font-family: $ns;
    text-align: justify;
    line-height: 1.6; // Improve readability
    background: $light;
    color: $dark;
    margin: auto;

    p {
        margin-bottom: 1rem; // Add space between paragraphs
        break-inside: avoid; // Prevent paragraphs from breaking across columns
    }

    @media (max-width: 768px) {
        width: 100%;
        font-size: 0.9rem;
        padding: 1.5rem;
    }

    @media (max-width: 576px) {
        font-size: 0.8rem;
        padding: 1rem;
    }
}

.drop-cap {
    font-size: 2rem;
    font-weight: 700;
    font-family: $mn;
    margin-top: 2rem;
    margin-bottom: 2rem; // Keep space below the title
}

@media (max-width: 768px) {
    .content-body {
        column-count: 1; // Revert to single column on smaller screens
    }
}

.hero-container {
    position: absolute;
    top: 60px;
}

.hero {
    width: 100%;
    display: flex;
    flex-direction: column;
    color: $dark;
    padding-bottom: 4rem;

    h2 {
        font-size: 7rem;
        font-weight: 700;
        font-family: $ds;
    }
}

.hero-title {
    a {
        text-decoration: none;
        color: $light;
        background: $dark;
        padding: 0.5rem 1rem;
        border-radius: 30px;
        z-index: 2;
        margin: 2rem;
    }
}
.hero-content {
    max-width: 800px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    margin-left: 2rem;
    font-size: 1.4rem;
    font-weight: 400;
    font-family: $ds;
    padding: 2rem;
}

.centered {
    @include centerFlex;
}

.ma {
    @include ma;
}

.flex-row {
    @include flexRow;
}

.flex-column {
    @include flexColumn;
}

.light {
    background: $light;
}

.orange {
    background: $orange;
}

.red {
    background: $red;
}

.blue {
    background: $blue;
}

.centered-title {
    @include centerFlex;
    font-family: $ds;
    font-size: 4rem;
    font-weight: 700;
    span:nth-child(1) {
        font-family: $ds;
    }
    span:nth-child(2) {
        font-family: $hg;
    }
    span:nth-child(3) {
        font-family: $mn;
    }
}

.btn {
    transition: $eio;
    cursor: pointer;
    &:hover {
        background: $dark-active;
        transition: $eio;
    }
}

.video {
    width: 100%;
    height: auto;
}
