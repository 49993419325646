@import 'utils';

.tray {
    @include centerFlex;
    position: fixed;
    bottom: 0;
    height: 40px;
    width: 100%;
    border-radius: 30px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    margin-bottom: 1rem;
    transition: $eio;

    ul {
        list-style: none;
        display: flex;
        justify-content: space-evenly;
        flex-direction: row;
    }

    li {
        margin-right: 3rem;
        
        @media (max-width: 768px) {
            margin-right: 1.5rem;
        }
        
        @media (max-width: 576px) {
            margin-right: 0.5rem;
        }
    }

    a {
        text-decoration: none;
        color: $dark;
        padding: 0.5rem;
        border-radius: 30px;
        background: none;
        cursor: pointer;
        transition: $eio;

        &:hover {
            padding: 0.5rem 1.375rem;
            border-radius: 30px;
            background: $dark;
            color: $light;
            transition: $eio;
        }

        &:active {
            padding: 0.5rem 1.375rem;
            border-radius: 30px;
            background: $dark;
            color: $light;
            transition: $eio;
        }
    }
}

.tray-hidden {
    @include centerFlex;
    position: fixed;
    bottom: -90px;
    height: 40px;
    width: 100%;
    border-radius: 30px;
    background: $dark;
    // backdrop-filter: blur(10px);
    // -webkit-backdrop-filter: blur(10px);
    margin-bottom: 1rem;
    transition: $eio;

    ul {
        list-style: none;
        display: flex;
        justify-content: space-evenly;
        flex-direction: row;
    }

    li {
        margin-right: 3rem;
        
        @media (max-width: 768px) {
            margin-right: 1.5rem;
        }
        
        @media (max-width: 576px) {
            margin-right: 0.5rem;
        }
    }

    a {
        text-decoration: none;
        color: $light;
        padding: 0.5rem;
        border-radius: 30px;
        background: none;
        cursor: pointer;
        transition: $eio;

        &:hover {
            padding: 0.5rem 1.375rem;
            border-radius: 30px;
            background: $light;
            color: $dark;
            transition: $eio;
        }
    }
}

/* Add this to your CSS file */
@media screen and (max-width: 1280px) {
    .nav-item span {
        display: none;
    }
}
@media (max-width: 768px) {
    .nav-item span {
      display: none;
    }
}

.nav-item {
    &.active {
        padding: 0.5rem 1.375rem;
        border-radius: 30px;
        background: $light;
        color: $dark;
        transition: $eio;
    }
}
