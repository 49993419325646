@import 'utils';

.header-container {
    width: 100%;
    height: 3.75rem;
    position: fixed;
    top: 0;
    z-index:4;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
}

.logo-container {
    @include centerFlex;
}


