@import 'utils';

@media screen and (max-width: 1280px) {
    .hero {
        max-width: 100%;
        @include centerFlex;
        h2 {
            font-size: 4rem;
        }

        p {
            @include ma;
            max-width: 100%;
            font-size: 1rem;
            padding: 1rem;
            word-wrap: break-word;
        }
    }

    .flex-row {
        flex-direction: column;
    }
}
