@import 'utils';

*::before, *::after, * {
    box-sizing: border-box;
}

* {
    margin: 0;
    padding: 0;
}

body {
    color: $light;
    background: $dark;
    max-width: 100%;
    overflow-x: hidden;
    font-family: $ns;
}

.app-container {
    @include wh100;
    @include grid1;
    overflow-x: hidden;
}

@import 'Header';
@import 'SideMenu';
@import 'Page';
@import 'Home';
@import 'Buttons';
@import 'Tray';
@import 'Footer';
@import 'Tablet';
@import 'Mobile';

