.side-menu-container {
    width: 14rem;
    height: 100vh;
    border-right: 1px solid $dark-active;
    position: fixed;
    left: 0;
    top: 3.75rem;
    z-index: 2;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    transition: $eio;

    ul {
        list-style: none;
        margin-left: 1.2rem;
    }

    li {
        margin: 2rem 0;
    }

    a {
        text-decoration: none;
        padding: 0.5rem;
        border-radius: 30px;
        background: none;
        transition: $eio;
        color: $light;

        &:hover {
            transition: $eio;
            background: $light;
            padding: 0.5rem 1.375rem;
            border-radius: 30px;
            color: $dark;
        }
    }
}

.side-menu-container-hidden {
    width: 14rem;
    height: 100vh;
    border-right: 1px solid $dark-active;
    position: fixed;
    left: -14rem;
    top: 3.75rem;
    z-index: 2;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    transition: $eio;

    ul {
        list-style: none;
        margin-left: 1.2rem;
    }

    li {
        margin: 2rem 0;
    }

    a {
        text-decoration: none;
        padding: 0.5rem;
        border-radius: 30px;
        background: none;
        color: $light;
        transition: $eio;

        &:hover {
            background: $light;
            padding: 0.5rem 1.375rem;
            border-radius: 30px;
            color: $dark;
            transition: $eio;
        }
    }

}