@import 'utils';
.back-btn {
    position: sticky;
    top: 80px;
    padding: 10px 15px;
    background-color: $light;
    color: $dark;
    text-decoration: none;
    border-radius: 5px;
    font-size: 1.2rem;
    font-family: $mn;
    margin-left: 1.5em;
    font-weight: bold;
    transition: all 0.3s ease;
    z-index: 3;

    &:hover {
        background-color: darken($light, 10%);
    }
}

.read-book-btn {
    @include centerFlex;
    position: fixed;
    top: 3.75rem;
    right: 2rem;
    width: 10rem;
    padding: 10px 20px;
    background-color: $light;
    color: $dark;
    text-decoration: none;
    border-radius: 5px;
    font-size: 1.2rem;
    font-weight: bold;
    transition: all 0.3s ease;
    z-index: 3;
    cursor: pointer;

    @media (max-width: 992px) {
        right: 1.5rem;
        width: 8rem;
    }

    @media (max-width: 768px) {
        right: 1rem;
        width: 6rem;
        font-size: 1rem;
        padding: 8px 16px;
    }

    @media (max-width: 576px) {
        right: 0.5rem;
        width: 5rem;
        font-size: 0.9rem;
        padding: 6px 12px;
    }

    &:hover {
        background-color: darken($light, 10%);
    }
}

.hamburger-btn {
    position: relative;
    bottom: 0;
    @include centerFlex;
    flex-direction: column;
    cursor: pointer;
    padding: 0.5rem 1rem;
    border-radius: 30px;
    background: $dark;
    margin-bottom: 0.5rem;
}

.bar {
    width: 20px;
    height: 4px;
    background-color: $light;
    margin-top: 0.250rem;
    transition: $eio;
}

.bar-1-open {
    width: 20px;
    height: 4px;
    background-color: $light;
    margin-top: 0.250rem;
    transform: rotate(-45deg) translateY(2px) translateX(-2px);
    transition: $eio;
}

.bar-2-hidden {
    opacity: 0;
    transition: $eio;
}

.bar-3-open {
    width: 20px;
    height: 4px;
    background-color: $light;
    margin: 0.125rem 0;
    transform: rotate(45deg) translateY(-2px) translateX(-2px);
    transition: $eio;
}
